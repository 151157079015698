<template>
  <div class="modal-content animated fadeIn">
    <div class="modal-header">Załaduj / Wybierz plik</div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <vue-dropzone
            ref="modalDropzone"
            id="modal-dropzone"
            :options="dropzoneOptions"
            @vdropzone-file-added="handleFileInput($event)"
            @vdropzone-success="successUpdated"
            @vdropzone-error="failedUpdated"
          >
          </vue-dropzone>

          <div v-show="loadingBar" class="overlay">
            <div class="overlay-background"></div>
            <div class="sk-spinner sk-spinner-wave">
              <div class="sk-rect1"></div>
              <div class="sk-rect2"></div>
              <div class="sk-rect3"></div>
              <div class="sk-rect4"></div>
              <div class="sk-rect5"></div>
            </div>
          </div>
        </div>
        <div class="col-12"></div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        @click="clickHideModal()"
        class="btn btn-white"
        data-dismiss="modal"
      >
        {{ $t("close") }}
      </button>
      <button type="button" @click="clickSaveModal()" class="btn btn-primary">
        {{ $t("set") }}
      </button>
    </div>
  </div>
</template>

<style>
.dz-preview-selected {
  border: 4px solid blue;
}
.dz-preview {
  width: 150px !important;
  height: 150px !important;
}

.dz-image img {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover !important;
}

.dropzone {
  overflow-y: scroll;
  max-height: 50vh;
}
</style>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2055;
}

.overlay-background {
  position: absolute;

  height: 100%;
  width: calc(100% - 30px);
  opacity: 0.4;
  margin: 0 15px;

  background: gray;
}

/* .loadingBar {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2600;
  padding: 0 15px;
}

.loadingBar .overlayy {
  height: 100%;
  width: 100%;
  opacity: 0.6;
  background: gray;
} */
</style>

<script>
import { mapActions, mapState } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "FileUploadModal",
  data() {
    return {
      loading: false,
      selectedImage: null,
      files: [],
      dropzoneOptions: {
        url: "",

        maxFilesize: 2,
        dictDefaultMessage: this.$t("dropzone_input_messages"),
        displayExistingFile: [],
        headers: {
          Authorization: `${this.$http.getToken()}`,
          Accept: `application/json`,
        },
      },
    };
  },
  computed: {
    ...mapState("modalWindow", [`modalData`]),
    loadingBar() {
      return this.loading;
    },
    filesListUrl() {
      const url = `${this.$http.getBaseUrl()}${this.$route.params.shop_name}/${
        this.$route.params.shop_lang
      }/files`;

      return this.modalData.data.dir
        ? `${url}/${this.modalData.data.dir}`
        : `${url}/all`;
    },
  },
  created() {
    this.dropzoneOptions.url = this.filesListUrl;
  },
  mounted() {
    this.getData().then(() => {
      this.addFilesFormServer();
    });
  },
  methods: {
    failedUpdated(file, message) {
      const elements = document.querySelectorAll(".dz-file-preview");
      for (const element of elements) {
        const filename = element.querySelectorAll(".dz-filename span")[0]
          .textContent;
        const errorMessage = element.querySelectorAll(
          ".dz-error-message span"
        )[0];
        if (filename === file.name && message.message !== undefined) {
          errorMessage.textContent = message.message;
        }
      }
    },
    successUpdated(file, response) {
      this.files.push(response);
    },
    handleFileInput(data) {
      data.previewElement.addEventListener("click", this.clickFileSource);
    },
    clickFileSource(event) {
      this.addSelected(event);
    },
    removeSelected() {
      const selected = document.querySelectorAll(".dz-preview-selected");
      selected.forEach((element) => {
        element.classList.remove("dz-preview-selected");
      });
    },
    addSelected(event) {
      const dzPreviewElem = event.target.closest(".dz-preview");
      if (!dzPreviewElem.className.includes("dz-processing")) {
        this.removeSelected();
        dzPreviewElem.classList.add("dz-preview-selected");
      } else {
        if (
          !dzPreviewElem.className.includes("dz-error") &&
          dzPreviewElem.className.includes("dz-complete")
        ) {
          this.removeSelected();
          dzPreviewElem.classList.add("dz-preview-selected");
        }
      }
    },
    ...mapActions("modalWindow", [`hideModal`, "setData"]),
    clickSaveModal() {
      const selected = document.querySelector(
        ".dz-preview-selected .dz-filename span"
      );
      if (selected) {
        const selectedName = selected.textContent;
        let file = this.files.filter((file) => {
          return file.name === selectedName;
        });

        if (file.length > 0) {
          this.setData({ data: { image_url: file[0].url } });
          this.clickHideModal();
        }
      }
    },
    clickHideModal() {
      this.$destroy;
      this.hideModal();
    },
    setLoading(e) {
      this.loading = e;
    },
    addFilesFormServer() {
      this.files.forEach((element) => {
        let file = {
          name: element.name,
          size: element.size,
          type: element.type,
        };
        if (this.$refs.modalDropzone !== undefined) {
          this.$refs.modalDropzone.manuallyAddFile(file, element.url);
        }
      });
      this.setLoading(false);
    },
    getData() {
      this.setLoading(true);
      this.$emit("loading", true);
      return this.$http
        .get(this.filesListUrl)
        .then((resp) => {
          this.files = resp.data.table.data;
          return this.files;
        })
        .catch(() => {
          this.$toastr.error(this.$t("error_download_data"));
          return false;
        });
    },
  },
};
</script>
