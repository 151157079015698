<template>
  <div class="modal-content animated fadeIn">
    <div class="modal-header">{{ title }}</div>
    <div class="text-center modal-body">
      {{ description }}
    </div>
    <div class="modal-footer">
      <button type="button" @click="clickHideModal()" class="btn btn-white">
        {{ $t("no") }}
      </button>
      <button type="button" @click="clickConfirmModal()" class="btn btn-danger">
        {{ $t("yes") }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.modal-body {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.modal-footer button {
  min-width: 100px;
}
</style>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "RemoveModal",
  data() {
    return {
      title: "",
      description: "",
      removeUrl: ""
    };
  },
  computed: {
    ...mapState("modalWindow", [`modalData`])
  },
  created() {
    this.title =
      this.modalData.data.title !== undefined
        ? this.modalData.data.title
        : this.$t("remove_element");
    this.description =
      this.modalData.data.messages !== undefined
        ? this.modalData.data.messages
        : this.$t("remove_messages");
    this.removeUrl =
      this.modalData.data.removeUrl !== undefined
        ? this.modalData.data.removeUrl
        : "";
  },
  methods: {
    ...mapActions("modalWindow", ["hideModal", "setData"]),
    clickConfirmModal() {
      if (this.removeUrl !== "") {
        this.$emit("loading", true);
        this.$http
          .delete(this.removeUrl)
          .then(() => {
            this.$toastr.success(this.$t("removed"));
            this.setRemovedData(true);
            this.clickHideModal();
          })
          .catch(err => {
            this.$toastr.error(err);
            this.setRemovedData(false);
            this.clickHideModal();
          });
      } else {
        this.$toastr.error(this.$t("empty_url"));
        this.setRemovedData(false);
        this.clickHideModal();
      }
    },
    setRemovedData(bool) {
      this.setData({
        data: {
          removed: bool
        }
      });
    },
    clickHideModal() {
      this.$destroy;
      this.hideModal();
    }
  }
};
</script>
