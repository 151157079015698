<template>
  <div class="modal-content animated fadeIn">
    <div class="modal-header">Edycja przycisku</div>
    <div class="modal-body">
      <form role="form">
        <div class="form-group">
          <label>{{ $t("button_text") }}</label>
          <input
            name="textBtn"
            type="text"
            v-model="currentGift.textBtn"
            class="form-control"
            v-validate="'required'"
          />

          <small v-show="errors.has('textBtn')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div class="form-group">
          <label>{{ $t("url") }}</label>
          <input
            name="url"
            type="text"
            v-model="currentGift.redirect_url"
            class="form-control"
            v-validate="'required'"
          />

          <small v-show="errors.has('url')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        @click="clickHideModal()"
        class="btn btn-white"
        data-dismiss="modal"
      >
        {{ $t("close") }}
      </button>
      <button type="button" @click="clickSaveModal()" class="btn btn-primary">
        {{ $t("set") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "EditButtonModal",
  data() {
    return {
      currentGift: {}
    };
  },
  created() {
    this.currentGift = this.$func.cloneObject(this.modalData.data);
  },
  computed: {
    ...mapState("modalWindow", [`modalData`])
  },
  methods: {
    ...mapActions("modalWindow", [`hideModal`, "setData"]),
    clickSaveModal() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.setData({ data: this.currentGift });
          this.$destroy;
          this.hideModal();
        }
      });
    },
    clickHideModal() {
      this.$destroy;
      this.hideModal();
    }
  }
};
</script>
