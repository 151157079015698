<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="vote-icon-add">
        <i
          @click="addNewGift()"
          class="fa fa-plus-circle"
          :class="{
            'disabled-sort-btn': isDisabledAddBtn()
          }"
        >
        </i>
      </div>

      <div
        v-for="(gift, index) in getGifts"
        class="vote-item"
        :key="`gift-` + gift.id"
        :id="`gift-` + gift.id"
      >
        <form :data-vv-scope="`giftForm-${gift.id}`">
          <div class="row">
            <div class="col-12 col-md-10">
              <div class="vote-actions">
                <div>
                  <a
                    class="sort-button"
                    :class="{
                      'disabled-sort-btn': isDisabledSortUp(index, gift)
                    }"
                    @click="sort(index, index - 1, gift)"
                  >
                    <i class="fa fa-chevron-up"> </i>
                  </a>
                  <div>{{ index + 1 }}</div>
                  <a
                    class="sort-button"
                    :class="{
                      'disabled-sort-btn': isDisabledSortDown(index, gift)
                    }"
                    @click="sort(index, index + 1, gift)"
                  >
                    <i class="fa fa-chevron-down"> </i>
                  </a>
                </div>
              </div>
              <div class="vote-info">
                <div class="row">
                  <div class="col-12 col-lg-2 mb-2">
                    <div class="edit-img-container">
                      <img
                        :src="gift.img_url !== '' ? gift.img_url : defaultImg"
                      />
                      <div v-if="!isDisabled(gift)" class="overlay">
                        <a class="button" @click="editImg(gift)">{{
                          $t("edit")
                        }}</a>
                      </div>
                    </div>
                    <div>
                      <input
                        name="image"
                        type="hidden"
                        v-model="gift.img_url"
                        v-validate="'required'"
                        :data-vv-scope="`giftForm-${gift.id}`"
                      />
                      <small
                        v-show="errors.has(`giftForm-${gift.id}.image`)"
                        class="text-danger pl-2 form-valid-mes"
                      >
                        {{ $t("field_required") }}
                      </small>
                    </div>
                  </div>
                  <div class="col-12 col-lg-10">
                    <div class="mb-1">
                      <input
                        name="category"
                        class="form-control"
                        type="text"
                        v-model="gift.category"
                        :disabled="isDisabled(gift)"
                        :placeholder="$t('category')"
                        v-validate="'required'"
                        :data-vv-scope="`giftForm-${gift.id}`"
                      />

                      <small
                        v-show="errors.has(`giftForm-${gift.id}.category`)"
                        class="text-danger pl-2 form-valid-mes"
                      >
                        {{ $t("field_required") }}
                      </small>
                    </div>
                    <div class="mb-1">
                      <input
                        name="title"
                        class="w-100 form-control"
                        type="text"
                        v-model="gift.title"
                        :disabled="isDisabled(gift)"
                        :placeholder="$t('title')"
                        v-validate="'required'"
                        :data-vv-scope="`giftForm-${gift.id}`"
                      />
                      <small
                        v-show="errors.has(`giftForm-${gift.id}.title`)"
                        class="text-danger pl-2 form-valid-mes"
                      >
                        {{ $t("field_required") }}
                      </small>
                    </div>
                    <div class="mb-1">
                      <textarea
                        name="description"
                        rows="7"
                        class="w-100 form-control"
                        v-model="gift.description"
                        :disabled="isDisabled(gift)"
                        :placeholder="$t('description')"
                        v-validate="'required'"
                        :data-vv-scope="`giftForm-${gift.id}`"
                      ></textarea>
                      <small
                        v-show="errors.has(`giftForm-${gift.id}.description`)"
                        class="text-danger pl-2 form-valid-mes"
                      >
                        {{ $t("field_required") }}
                      </small>
                    </div>
                    <div class="col-12 col-md-3 my-2 float-md-right">
                      <div>
                        <a
                          class="btn btn-custom"
                          @click="editButton(gift)"
                          :disabled="isDisabled(gift)"
                          @mouseover="hoverBtn = true"
                          @mouseleave="hoverBtn = false"
                          v-text="textButton(gift)"
                        ></a>
                      </div>
                      <div>
                        <input
                          name="redirectUrl"
                          type="hidden"
                          v-model="gift.redirect_url"
                          v-validate="'required'"
                          :data-vv-scope="`giftForm-${gift.id}`"
                        />
                        <small
                          v-show="errors.has(`giftForm-${gift.id}.redirectUrl`)"
                          class="text-danger pl-2 form-valid-mes"
                        >
                          {{ $t("field_required") }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2">
              <div class="vote-icon">
                <i
                  v-if="isDisabled(gift) == true"
                  @click="clickEditItem(gift)"
                  :id="`btn-edit-gift-` + gift.id"
                  class="fa fa-edit mx-2 vote-icon-edit"
                >
                </i>
                <i
                  v-else
                  @click="clickSaveItem(gift)"
                  class="fa fa-save mx-2 vote-icon-save"
                >
                </i>
                <i
                  v-if="gift.id > 0"
                  @click="clickRemoveItem(gift)"
                  class="fa fa-trash mx-2 vote-icon-remove"
                >
                </i>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditButtonModal from "@/components/PresentationsGifts/Modals/EditButton.vue";
import FileUploadModal from "@/components/Modals/FileUpload.vue";
import RemoveModal from "@/components/Modals/Remove.vue";

Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

export default {
  name: "GiftItems",
  components: {},
  async created() {
    await this.getDate();
    this.$emit("loading", false);
  },
  data() {
    return {
      gifts: [],
      editItems: [],
      newItems: [],
      hoverBtn: false,
      currentEditId: null,
      defaultImg: "https://cdn2.neonail.pl/all/default-placeholder.png",
      timerSort: null,
      timeSaveSort: 2000
    };
  },
  watch: {
    modalVisible: function(newVal) {
      if (newVal === false) {
        if (this.modalData.component !== undefined) {
          for (var i = 0; i < this.gifts.length; i++) {
            if (this.gifts[i].id === this.currentEditId) {
              switch (this.modalData.component) {
                case "EditButtonModal":
                  this.gifts[i].textBtn =
                    this.modalData.data.textBtn !== undefined
                      ? this.modalData.data.textBtn
                      : this.gifts[i].textBtn;
                  this.gifts[i].redirect_url =
                    this.modalData.data.redirect_url !== undefined
                      ? this.modalData.data.redirect_url
                      : this.gifts[i].redirect_url;
                  break;
                case "FileUploadModal":
                  this.gifts[i].img_url =
                    this.modalData.data.image_url !== undefined
                      ? this.modalData.data.image_url
                      : this.gifts[i].img_url;
                  break;
                case "RemoveModal":
                  if (
                    this.modalData.data.removed !== undefined &&
                    this.modalData.data.removed == true
                  ) {
                    this.gifts.splice(i, 1);
                  }
              }
              break;
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters("modalWindow", ["modalData", "modalVisible"]),
    getGifts() {
      return this.gifts;
    },
    giftUrl() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/presentations/gifts`;
    }
  },
  methods: {
    ...mapActions("modalWindow", ["showModal"]),
    sort(from, to, gift) {
      let disabled = false;
      if (from > to) {
        disabled = this.isDisabledSortUp(from, gift);
      } else {
        disabled = this.isDisabledSortDown(from, gift);
      }

      if (disabled == false) {
        this.gifts.move(from, to);
        clearTimeout(this.timerSort);
        this.timerSort = setTimeout(() => {
          this.saveSort();
        }, this.timeSaveSort);
      }
    },
    isDisabledSortUp(index, gift) {
      if (gift.id < 1) {
        return true;
      } else {
        let minElement = this.getElementWithMinId();
        if (index == 0) {
          return true;
        } else if (index == 1 && minElement.id < 1) {
          return true;
        }
      }
      return false;
    },
    isDisabledSortDown(index, gift) {
      if (gift.id < 1) {
        return true;
      } else if (index == this.getGifts.length - 1) {
        return true;
      }
      return false;
    },
    isDisabledAddBtn() {
      let element = this.getElementWithMinId();
      if (element.id < 1) {
        return true;
      }
      return false;
    },
    saveSort() {
      this.$emit("loading", true);

      let gifts = this.getGifts.filter(element => {
        return element.id > 0;
      });
      let arraySort = gifts.map(element => {
        return element.id;
      });
      this.$http
        .post(`${this.giftUrl}/sort`, {
          array: arraySort.reverse()
        })
        .then(() => {
          this.$toastr.success(this.$t("save_sort"));
          this.$emit("loading", false);
        })
        .catch(() => {
          this.$toastr.error(this.$t("error_download_data"));
          this.$emit("loading", false);
        });
    },
    getDate() {
      this.$emit("loading", true);
      return this.$http
        .get(this.giftUrl)
        .then(resp => {
          this.gifts = resp.data.data;
          return this.gifts;
        })
        .catch(() => {
          this.$toastr.error(this.$t("error_download_data"));
          return false;
        });
    },
    isDisabled(gift) {
      if (gift.id <= 0) {
        return false;
      }
      if (this.editItems.length < 0) {
        return true;
      } else {
        let el = this.editItems.filter(element => {
          return element.id == gift.id;
        });
        if (el.length < 1) {
          return true;
        }
      }
      return false;
    },
    textButton(gift) {
      if (this.isDisabled(gift) == false && this.hoverBtn == true) {
        return this.$t("edit_button");
      }
      return gift.textBtn == undefined || gift.textBtn == ""
        ? this.$t("edit_button")
        : gift.textBtn;
    },
    clickEditItem(gift) {
      this.editItems.push(gift);
    },
    clickSaveItem(gift) {
      this.$validator.validateAll(`giftForm-${gift.id}`).then(result => {
        if (result) {
          let indexGift = this.gifts.findIndex(element => {
            return element.id == gift.id;
          });
          if (indexGift !== -1) {
            let editGift = this.gifts[indexGift];
            this.$emit("loading", true);
            this.$http
              .customRequest({
                url:
                  editGift.id < 1
                    ? this.giftUrl
                    : `${this.giftUrl}/${editGift.id}`,
                method: editGift.id < 1 ? "POST" : "PUT",
                data: editGift
              })
              .then(response => {
                this.gifts[indexGift] = response.data.data;
                this.$toastr.success("Poprawnie zapisano");

                let indexEdit = this.editItems.findIndex(element => {
                  return element.id == gift.id;
                });
                this.editItems.splice(indexEdit, 1);
                this.$emit("loading", false);
              })
              .catch(err => {
                this.$toastr.error(err);
                this.$emit("loading", false);
              });
          }
        }
      });
    },
    clickRemoveItem(gift) {
      if (gift.id > 0) {
        this.currentEditId = gift.id;
        this.showModal({
          component: RemoveModal,
          data: {
            removeUrl: `${this.giftUrl}/${this.currentEditId}`
          }
        });
      }
    },
    editButton(gift) {
      if (this.isDisabled(gift) == true) {
        window.open(gift.redirect_url, "_blank");
      } else {
        this.currentEditId = gift.id;
        this.showModal({
          component: EditButtonModal,
          data: gift
        });
      }
    },
    editImg(gift) {
      this.currentEditId = gift.id;
      this.showModal({
        component: FileUploadModal,
        data: {
          dir: "presentation",
          gift: gift
        }
      });
    },
    addNewGift() {
      let minElement = this.getElementWithMinId();
      if (minElement.id > 1) {
        minElement.id = 1;
      }
      if (this.gifts.length == 0 || minElement.id > 0) {
        let defaultGift = {
          category: "",
          description: "",
          id: minElement.id - 1,
          img_url: "",
          redirect_url: "",
          title: ""
        };
        this.gifts.unshift(defaultGift);
      }
    },

    getElementWithMinId() {
      let element = {
        id: 1
      };
      if (this.gifts.length > 0) {
        element = this.gifts.reduce(function(prev, curr) {
          return prev.id < curr.id ? prev : curr;
        });
      }
      return element;
    }
  }
};
</script>

<style scoped>
.vote-item {
  padding: 10px 15px;
}
.sort-button:not(.disabled-sort-btn):hover {
  color: blue !important;
}
.disabled-sort-btn {
  color: rgba(216, 216, 216, 0.575) !important;
  cursor: not-allowed;
}
.vote-icon {
  font-size: 22px;
  cursor: pointer;
}

.vote-icon-edit {
  color: gray;
}
.vote-icon-edit:hover {
  color: blue;
}

.vote-icon-remove {
  /* color: rgb(209, 22, 22); */
  color: gray;
}
.vote-icon-remove:hover {
  color: red;
}

.vote-icon-save {
  color: gray;
}
.vote-icon-save:hover {
  color: green;
}

.form-valid-mes {
  font-size: 11px !important;
}

input[type="text"]:disabled,
textarea:disabled {
  color: black;
  border: none;
  background: none;
}

.btn-custom {
  color: #fff !important;
  background-color: black;
  text-decoration: none !important;
  padding: 10px 20px;
  min-width: 130px;
}

.vote-icon-add {
  padding: 10px 15px;
  color: green !important;
  font-size: 22px;
  cursor: pointer;
  text-align: right;
  display: block;
  color: #e8e9ea;
}

.edit-img-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.edit-img-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease;
}

.edit-img-container:hover .overlay {
  background: rgba(33, 150, 243, 0.8);
}

.edit-img-container img {
  position: absolute;
  object-fit: cover;
  width: 150px;
  height: 150px;
  left: 0;
}

.edit-img-container .button {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  opacity: 0;
  /* transition: opacity 0.35s ease; */
  text-align: center;
  color: white;
  border: solid 2px white;
}

.edit-img-container .button:hover {
  background: white;
}

.edit-img-container:hover .button {
  opacity: 1;
}
</style>
